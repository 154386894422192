import styled from "styled-components";
import { Drawer } from "@mui/material";
import FHTypography from "src/styles/Typography";
import { FaXmark } from "react-icons/fa6";
import {
  FLEX_COL_CENTER,
  FLEX_ROW,
  FLEX_ROW_CENTER,
} from "src/styles/styleGuide";

const AdditionalInfoDrawer = ({
  title = "Additional Info",
  shouldOpenDrawer,
  setShouldOpenDrawer,
  additionalInfo = [],
}) => {
  if (!additionalInfo?.length) {
    return <></>;
  }
  return (
    <CustomableDrawer open={shouldOpenDrawer} anchor="right">
      <InnerContainer>
        <HeadingTitle>
          <FHTypography variant="h4" weight="semi" colorType="heading">
            {title}
          </FHTypography>
          <span onClick={() => setShouldOpenDrawer(false)}>
            <FaXmark size={"100%"} />
          </span>
        </HeadingTitle>
        <InfoConatiner>
          <ul>
            {additionalInfo?.map(eachInfo => (
              <li key={eachInfo}>
                {" "}
                <FHTypography variant="h6" weight="md" colorType="heading">
                  {eachInfo}
                </FHTypography>
              </li>
            ))}
          </ul>
        </InfoConatiner>
      </InnerContainer>
    </CustomableDrawer>
  );
};

export default AdditionalInfoDrawer;

const CustomableDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background-color: #fff;
    width: 400px;
    height: 100vh;
    margin-bottom: 30px;
    box-sizing: border-box;
    background-color: #fff;
    justify-content: space-between;
    overflow-y: hidden;
    @media (max-width: 450px) {
      width: 100%;
    }
  }
`;

const InnerContainer = styled.div`
  ${FLEX_COL_CENTER};
  width: 100%;
`;

const HeadingTitle = styled.div`
  ${FLEX_ROW};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
  span {
    ${FLEX_ROW_CENTER};
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
    padding: 0.5rem;
  }
`;

const InfoConatiner = styled.div`
  padding-left: 0.5rem;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  ol {
    list-style-position: inside;
    padding: 0;
  }
  li {
    list-style: disc;
    list-style-position: inside;
    padding: 0.5rem;
    h6 {
      display: inline-block;
    }
  }
`;
